import React, { useEffect, useState } from 'react'
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemIcon,
	Checkbox,
	Radio,
	RadioGroup,
	Button,
	IconButton,
	Divider,
	Box,
	FormControlLabel,
} from '@mui/material'
import AppHeader from 'components/AppHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Poll, PollChoice } from 'lib/supabase'
import PollButtons from './PollButtons'
import { useUserContext } from 'contexts/UserContext'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { voteController } from 'utils'
import { set } from 'react-hook-form'

type Props = {
	canViewButtons: boolean
	poll: Poll
	choices: PollChoice[]
	onBack: () => void
	onEdit: () => void
	onRemind: () => void
	onVote: (voteChoices: voteController[]) => void
	onReset: () => void
}

const ViewPoll: React.FC<Props> = ({
	canViewButtons,
	poll,
	choices,
	onBack,
	onEdit,
	onRemind,
	onVote,
	onReset,
}) => {
	const [alreadyVoted, setAlreadyVoted] = useState(false)
	const profile = useUserContext().profile
	const [showConfirmReset, setShowConfirmReset] = React.useState(false)
	const { showSnackbar } = useSnackbarContext()
	const [choiceList, setChoiceList] = React.useState([] as voteController[])
	const [radioChoice, setRadioChoice] = React.useState('')

	useEffect(() => {
		const voteController: voteController[] = []
		choices.forEach((choice, index) => {
			const checked =
				Array.isArray(choice.votes) && profile?.id
					? choice.votes.indexOf(profile.id) !== -1
					: false
			if (checked) setAlreadyVoted(true)
			voteController.push({
				choice_id: choice.id,
				choice: choice.choice,
				checked: checked,
			})
		})
		setChoiceList(voteController)
	}, [choices])

	const handleVoteChoice = async (choiceId: string) => {
		if (poll?.choose_multiple) {
			const updatedChoiceList = await choiceList.map((choice) => {
				if (choice.choice_id === choiceId) {
					return {
						...choice,
						checked: !choice.checked,
					}
				}
				return choice
			})
			setChoiceList(updatedChoiceList)
		} else {
			const prevChoice = choiceList.find(
				(c) => c.checked
			) as voteController

			const updatedChoices = choiceList.map((choice) => {
				if (prevChoice && choice.choice_id === prevChoice.choice_id) {
					return {
						...choice,
						checked: false,
					}
				} else if (choice.choice_id === choiceId) {
					return {
						...choice,
						checked: true,
					}
				} else {
					return choice
				}
			})
			if (updatedChoices) {
				setChoiceList(updatedChoices)
				const selectedChoice = updatedChoices.find((c) => c.checked)
				setRadioChoice(selectedChoice ? selectedChoice.choice_id : '')
			}
		}
	}

	const handleVote = (choiceList: voteController[]) => {
		setAlreadyVoted(true)
		onVote(choiceList)
	}

	const handleReset = () => {
		onReset()
		setChoiceList(
			choiceList.map((choice) => ({ ...choice, checked: false }))
		)
		setAlreadyVoted(false)
		showSnackbar('Poll has been reset', 'success')
		setShowConfirmReset(false)
	}

	const getVoteCount = (choiceId: string) => {
		if (alreadyVoted) {
			const choice = choices.find((c) => c.id === choiceId)
			if (choice) {
				return choice.votes.length === 1
					? '1 vote'
					: choice.votes.length + ' votes'
			} else {
				return ''
			}
		}
	}

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<AppHeader
					leftComponent={
						<IconButton
							size='large'
							edge='start'
							sx={{ color: 'white' }}
							onClick={onBack}
						>
							<ArrowBackIcon />
						</IconButton>
					}
					title='Poll Details'
				/>
			</Box>

			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					alignContent: 'center',
					margin: '20px 10px 0px 10px',
				}}
			>
				<Typography
					fontSize={'1.2rem'}
					fontWeight={'bold'}
					marginBottom={'10px'}
				>
					{poll?.title}
				</Typography>
				<Typography marginBottom={'20px'}>{poll?.message}</Typography>
				<Divider style={{ width: '90%' }} />
			</Box>
			<Box style={{ marginLeft: '20px', marginBottom: '10px' }}>
				{poll?.choose_multiple ? (
					<List>
						{choices &&
							choiceList.map(
								(choice) =>
									choice.choice_id !== '' && (
										<ListItem
											key={choice.choice_id}
											disablePadding
										>
											<Divider />
											<ListItemButton>
												<ListItemIcon
													style={{
														minWidth: '30px',
													}}
												>
													<Checkbox
														id={choice.choice_id}
														value={choice.choice_id}
														edge='start'
														disabled={alreadyVoted}
														checked={choice.checked}
														onChange={() =>
															handleVoteChoice(
																choice.choice_id
															)
														}
														tabIndex={-1}
														disableRipple
													/>
												</ListItemIcon>
												<ListItemText
													primary={choice.choice}
													secondary={getVoteCount(
														choice.choice_id
													)}
												/>
											</ListItemButton>
										</ListItem>
									)
							)}
					</List>
				) : (
					<RadioGroup
						name='radio-buttons-group'
						style={{ marginBottom: '40px' }}
						value={radioChoice}
					>
						{choices &&
							choiceList.map(
								(choice) =>
									choice.choice_id !== '' && (
										<div
											key={choice.choice_id}
											style={{
												paddingLeft: '10px',
												paddingTop: '10px',
											}}
										>
											<FormControlLabel
												key={choice.choice_id}
												control={
													<Radio
														value={choice.choice_id}
													/>
												}
												value={choice.choice_id}
												checked={choice.checked}
												disabled={alreadyVoted}
												label={choice.choice}
												onChange={() =>
													handleVoteChoice(
														choice.choice_id
													)
												}
											/>
											<Typography
												fontSize={'0.9rem'}
												paddingLeft={'30px'}
												style={{
													display: alreadyVoted
														? 'block'
														: 'none',
												}}
											>
												{getVoteCount(choice.choice_id)}
											</Typography>
										</div>
									)
							)}
					</RadioGroup>
				)}
			</Box>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					gap: '20px',
					paddingLeft: '20px',
				}}
			>
				<Button
					variant='contained'
					disabled={
						alreadyVoted ||
						choiceList.findIndex((c) => c.checked) === -1
					}
					onClick={() => handleVote(choiceList)}
				>
					Vote
				</Button>

				<Typography
					style={{ display: alreadyVoted ? 'block' : 'none' }}
					fontStyle={'italic'}
					fontSize={'0.8rem'}
				>
					Thank you for voting!
				</Typography>

				<Typography
					style={{ display: alreadyVoted ? 'none' : 'block' }}
					fontStyle={'italic'}
					fontSize={'0.8rem'}
				>
					Results will be shown after voting.
				</Typography>
			</div>

			<PollButtons
				visible={canViewButtons}
				resetEnabled={!alreadyVoted}
				onEditClick={onEdit}
				onSendClick={onRemind}
				onResetClick={() => setShowConfirmReset(true)}
			/>

			<ConfirmationDialog
				isOpen={showConfirmReset}
				onClose={() => setShowConfirmReset(false)}
				onConfirm={handleReset}
				title={'Confirm Reset Poll'}
				content={
					'Do you want to permanently reset this poll?  All existing votes will be deleted. '
				}
				confirmButtonText='Reset Poll'
			/>
		</div>
	)
}

export default ViewPoll
