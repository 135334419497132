import { AppBar, Stack, Button } from '@mui/material'
import React from 'react'

type Props = {
	visible: boolean
	resetEnabled: boolean
	onEditClick: () => void
	onSendClick: () => void
	onResetClick: () => void
}
const PollButtons: React.FC<Props> = ({
	visible,
	resetEnabled,
	onEditClick,
	onSendClick,
	onResetClick,
}) => {
	if (visible) {
		return (
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<Stack
					direction={'column'}
					spacing={1}
					marginTop={2}
					marginX={10}
				>
					<Button onClick={onEditClick} variant='contained'>
						Edit Poll
					</Button>
					<Button
						variant='outlined'
						onClick={onResetClick}
						disabled={resetEnabled}
					>
						Reset Poll
					</Button>
					<Button onClick={onSendClick} variant='outlined'>
						Send Reminder
					</Button>
				</Stack>
			</AppBar>
		)
	} else {
		return null
	}
}

export default PollButtons
